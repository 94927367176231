import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../Components/Headers/Header';
import { useEffect } from 'react';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import styles from './ResponseList.module.css';
import getResponseListFromDB from './Utilities/getResponseListFromDB';
import getColumns from './Utilities/getColumnsResponseList';
import { supabase } from '../../Utilities/SupabaseClient';

// This component shows a table of all suppliers entered into the system
// by the user. The table has key information for each supplier in it.
const ResponseList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [responseListData, setResponseListData] = useState([]);
  const columns = getColumns(colors);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    async function getData() {
      const session = await supabase.auth.getSession();
      // console.log(session.data.session);
      const incomingResponseListData = await getResponseListFromDB(
        session.data.session.user.id
      );
      setResponseListData(incomingResponseListData);      
      setLoading(false);
    }
    getData();
  }, []);

  return (
    <>
      <SideBarPanel />
      <main className={styles.responselistmain}>
        <nav>
          <TopBar />
        </nav>
        <Box m='20px'>
          <Header title='ASSESSMENT RESPONSES' subtitle='Responses Received' />
          <div className={styles.responselisttablewrapper}>
            <Box
              m='40px 0 0 0'
              height='75vh'
              sx={{
                '& .MuiDataGrid-root': {
                  border: 'none',
                },
                '& .MuiDataGrid-cell': {
                  borderBottom: 'none',
                },
                '& .name-column--cell': {
                  color: colors.greenAccent[300],
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: colors.blueAccent[700],
                  borderBottom: 'none',
                },
                '& .MuiDataGrid-virtualScroller': {
                  backgroundColor: colors.primary[400],
                },
                '& .MuiDataGrid-footerContainer': {
                  borderTop: 'none',
                  backgroundColor: colors.blueAccent[700],
                },
                '& .MuiCheckbox-root': {
                  color: `${colors.greenAccent[200]} !important`,
                },
                '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                  color: `${colors.grey[100]} !important`,
                },
              }}
            >
              <DataGrid
                rows={responseListData}
                columns={columns}
                loading={loading}
                components={{ Toolbar: GridToolbar }}
                sx={{
                  fontSize: '14px',
                }}
              />
            </Box>
          </div>
        </Box>
      </main>
    </>
  );
};

export default ResponseList;

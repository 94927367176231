import { supabase } from '../../../Utilities/SupabaseClient';

// Gets the supplier information for a single supplier from
// the DB based on its UUID.
const getSupplierInfoFromDB = async (uuid) => {
  try {  
    const {data, err} = await supabase.from('suppliers').select('*').eq('uuid', uuid);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);    
    if (data) return data[0];
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default getSupplierInfoFromDB;
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import { ColorModeContext, useMode } from '../../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AddSupplier from '../AddSupplier/AddSupplier';
import SuppliersList from '../SuppliersList/SuppliersList';
import SupplierPage from '../SupplierPage/SupplierPage';
import SupplierInfoPage from '../SupplierInfoPage/SupplierInfoPage';
import AssessmentSelector from '../AsssessmentSelector/AssessmentSelector';
import ResponseList from '../ResponseList/ResponseList';
import ResponseSelector from '../ResponseSelector/ResponseSelector';

import AssessmentConfirmationPage from '../AssessmentConfirmationPage/AssessmentConfirmationPage';

import { AuthProvider } from '../../contexts/AuthContext';
import ProtectedRoute from '../../Components/ProtectedRoute/ProtectedRoute';
import SendAssessment from '../SendAssessment/SendAssessment';
import EmissionsDetail from '../EmissionsDetail/EmissionsDetail';
import NewPassword from '../NewPassword/NewPassword';
import AssessmentSelectorForUser from '../AsssessmentSelector/AssessmentSelectorForUser';

// Once auth this done, this component is shown. It has the front end routing
// structure.
const AppMain = ({ session }) => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path='/'
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/dashboard'
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/addsupplier'
                element={
                  <ProtectedRoute>
                    <AddSupplier />
                  </ProtectedRoute>
                }
              />

              <Route
                path='/supplierslist'
                element={
                  <ProtectedRoute>
                    <SuppliersList session={session} />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/assessmentresponses'
                element={
                  <ProtectedRoute>
                    <ResponseList session={session} />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/supplier/:supplierid'
                element={
                  <ProtectedRoute>
                    <SupplierPage />
                  </ProtectedRoute>
                }
              />
              {/* The route below is not supposed to be protected */}
              <Route
                path='/assessment/:assessmentid'
                element={<AssessmentSelector />}
              />
              <Route
                path='/showassessment/:assessmentnumber'
                element={
                  <ProtectedRoute>
                    <AssessmentSelectorForUser />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/response/:assessmentid'
                element={
                  <ProtectedRoute>
                    <ResponseSelector />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/sendassessment'
                element={
                  <ProtectedRoute>
                    <SendAssessment />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/emissionsdetail'
                element={
                  <ProtectedRoute>
                    <EmissionsDetail />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/newpassword'
                element={
                  <ProtectedRoute>
                    <NewPassword />
                  </ProtectedRoute>
                }
              />
              
              <Route
                path='/supplierpage/:supplierid'
                element={
                  <ProtectedRoute>
                    <SupplierInfoPage />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/confirmation'
                element={<AssessmentConfirmationPage />}
              />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AppMain;

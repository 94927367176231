import React from 'react';
import getEditableFieldName from '../Utilities/getEditableFieldName';
import styles from './SupplierInfoField.module.css';


const SupplierInfoField = ({ fieldName, fieldValue }) => {
  const fieldNameToUse = getEditableFieldName(fieldName);



	return (
		<div className={styles.supplierInfoFieldWrapper}>
			<div className={styles.supplierInfoFieldHeading}>
				{fieldNameToUse}
			</div>
			<div className={styles.supplierInfoFieldValue}>
				<span className={styles.supplierInfoFieldSpan}>
        {fieldValue}
				</span>
			</div>
		</div>
	);
};

export default SupplierInfoField;

import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the suppliers from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
export const getSuppliersKPIFromDB = async () => {
	try {
		const { data: sessionData, error: sessionError } =
			await supabase.auth.getSession();
		if (sessionError || !sessionData?.session?.user) {
			console.error(
				'Error getting session:',
				sessionError?.message || 'No active session'
			);
			return 0; // Return default value to prevent crashes
		}

		const userid = sessionData.session.user.id;
		// RLS should only return the suppliers associated with the user
		// that is logged in. However, using .eq here to add extra
		// protection.
		const { data, error } = await supabase
			.from('suppliers')
			.select('*')
			.eq('user_added_supplier_id', userid)
			.limit(10);
		// Error block here in case the catch block does not
		// recognize the error. Not sure how Supabase handles the error
		// message so including this here. May not be necessary.

		if (error) {
			console.error('Supabase error:', error.message);
      return 0;
		}

		return data ? data.length : 0;
	} catch (e) {
		console.log('catch: ', e);
		throw e;
	}
};

import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import getAssessmentNumberFromDB from '../AsssessmentSelector/Utilities/getAssessmentNumberFromDB';
import Assessment10001Response from '../SupplierAssessmentResponses/Assessment10001Response/Assessment10001Response';
import Assessment10002Response from '../SupplierAssessmentResponses/Assessment10002Response/Assessment10002Response';
import Assessment10003Response from '../SupplierAssessmentResponses/Assessment10003Response/Assessment10003Response';
import Assessment10004Response from '../SupplierAssessmentResponses/Assessment10004Response/Assessment10004Response';
import Assessment10005Response from '../SupplierAssessmentResponses/Assessment10005Response/Assessment10005Response';
import Assessment10006Response from '../SupplierAssessmentResponses/Assessment10006Response/Assessment10006Response';
import Assessment10007Response from '../SupplierAssessmentResponses/Assessment10007Response/Assessment10007Response';
import Assessment10008Response from '../SupplierAssessmentResponses/Assessment10008Response/Assessment10008Response';

// This component determines the assessment response to be shown
// based on the assessment uuid. It then shows that response.
const ResponseSelector = () => {
  const [assessmentNumber, setAssessmentNumber] = useState(0);
  const params = useParams();

  useEffect(() => {
    async function getAssessmentNumber() {
      const dbReturn = await getAssessmentNumberFromDB(params.assessmentid);
      console.log("dbReturn: ", dbReturn);
      if (dbReturn.length > 0) {
        if(dbReturn[0]['response']) setAssessmentNumber(dbReturn[0]['assessment_number']);
      }
    }
    getAssessmentNumber();
  }, [params.assessmentid]);

  return (
    <>
      {(assessmentNumber === 10001) && <Assessment10001Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10002) && <Assessment10002Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10003) && <Assessment10003Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10004) && <Assessment10004Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10005) && <Assessment10005Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10006) && <Assessment10006Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10007) && <Assessment10007Response assessmentid={params.assessmentid}/> }
      {(assessmentNumber === 10008) && <Assessment10008Response assessmentid={params.assessmentid}/> }
    </>
  );

}

export default ResponseSelector;
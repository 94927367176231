import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the # of suppliers from the DB who have
// been sent at least one assessment by the logged in user.
export const getAssessmentsSentKPIFromDB = async () => {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  try {
    const { data, err } = await supabase
      .from('assessments_sent')
      .select('supplier_uuid')
      .eq('sending_user_id', user.id);
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw err;

    if (!data || data.length === 0) {
      return [0, 0];
    }

    const idsArray = data.map((item) => item.supplier_uuid);
    const uniqueElements = [...new Set(idsArray)];
    
    return [data.length, uniqueElements.length];

  } catch (e) {
    console.log(e);
    throw e;
  }
};

import { useEffect, useState } from 'react';
import AssessmentResponseHeader from '../../../Components/Headers/AssessmentResponseHeader';
import getAssessmentTitle from '../../SupplierPage/Utilities/getAssessmentTitle';
import getAssessmentResponse from '../Utilities/getAssessmentResponse';
import ResponseItem from '../Subcomponents/AssessmentResponseItem'; 
import ResponseDate from '../Subcomponents/ReponseDate';
import styles from '../AssessmentResponse.module.css';

// Shows the responses that a supplier gave to Assessment10001.
const Assessment10001Response = ( { assessmentid } ) => {
  const title = getAssessmentTitle(10001);
  const [assessmentResponse, setAssessmentResponse] = useState(null);

  useEffect(() => {
    async function getResponseInfo() {
      const incomingResponse = await getAssessmentResponse('supplier_initial_assessment_1', assessmentid);
      setAssessmentResponse(incomingResponse);
    }
    getResponseInfo()

  }, [assessmentid]);

  // Blank component until assessmentResponse has a value from
  // the DB call.
  if (!assessmentResponse) return null;

  return(
    <main className={styles.assessmentresponsemain}>
      <div className={styles.assessmentresponsecontentwrapper}>
        <AssessmentResponseHeader title={title} />
        <ResponseItem 
          question='Company Name'
          answer={assessmentResponse['company_name']}         
        />
        <ResponseItem 
          question='Does your company have a climate change policy or program?'
          answer={assessmentResponse['climate_change_policy']}         
        />
        <ResponseItem 
          question='Describe your climate change policy or provide a link to it.'
          answer={assessmentResponse['climate_change_policy_description']}         
        />
        <ResponseItem 
          question='Does the company have a person or team responsible for measuring and/or managing emissions?'
          answer={assessmentResponse['climate_team_responsible']}         
        />
        <ResponseItem 
          question='Describe your climate team.'
          answer={assessmentResponse['climate_team_description']}         
        />
        <ResponseItem 
          question='Does the company currently measure its emissions?'
          answer={assessmentResponse['currently_measure_emissions']}         
        />
        <ResponseItem 
          question='Describe your climate team.'
          answer={assessmentResponse['currently_measured_emissions_program_description']}         
        />
        <ResponseItem 
          question='Describe your current emissions measurement program.'
          answer={assessmentResponse['currently_measured_emissions_program_description']}         
        />
        <ResponseItem 
          question='Which scopes are currently measured? How are the measurements done?'
          answer={assessmentResponse['scopes_measured_description']}         
        />
        <ResponseItem 
          question='How is your emissions data stored?'
          answer={assessmentResponse['emissions_data_stored_description']}         
        />
        <ResponseDate 
          assessmentResponse={assessmentResponse} 
        />
      </div>
    </main>
  );
}

export default Assessment10001Response;
import { supabase } from '../../../Utilities/SupabaseClient';

// Gets the response to an assessment from the DB.
const getAssessmentResponse = async (tablename, assessmentid) => {
	const { data, err } = await supabase
		.from(tablename)
		.select('*')
		.eq('assessment_uuid', assessmentid);
	// Error block here in case the catch block does not
	// recognize the error. Not sure how Supabase handles the error
	// message so including this here. May not be necessary.
	try {
		if (err) throw err;
		if (data) {
			let tableData = {};

			if (tablename === 'supplier_initial_assessment_2') {
				tableData = {
					ghg_reduction_targets_set: data[0]['climate_reduction_target_in_place'],
					offset_program_in_place: data[0]['offset_program_description'],
					has_done_a_climate_risk_assessment: data[0]['climate_risk_assessment'],
				};

				console.log('table2Data: ', tableData);
			} else if (tablename === 'supplier_initial_assessment_1') {
				tableData = {
					has_climate_policy: data[0]['climate_change_policy'],
					has_climate_team: data[0]['climate_team_responsible'],
				};
				console.log('table1Data: ', tableData);
			}

			return tableData;
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export default getAssessmentResponse;

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SupplierInfoField from './Subcomponents/SupplierInfoField';
import SidebarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import Header from '../../Components/Headers/Header';
import Box from '@mui/material/Box';
import getInitialAssessmentInfo from './Utilities/getInitialAssessmentInfo';
import getAssessmentResponse from './Utilities/getAssessmentResponse';
import getAssessmentIdFromDB from './Utilities/getAssessmentIdFromDB';
import getSupplierInfoFromDB from '../SupplierPage/Utilities/getSupplierInfoFromDB';
import styles from './SupplierInfoPage.module.css';

const SupplierInfoPage = () => {
	const params = useParams();

	const initialInfoState = {
		has_climate_policy: 'No Response Yet',
		has_climate_team: 'No Response Yet',
		ghg_reduction_targets_set: 'No Response Yet',
		offset_program_in_place: 'No Response Yet',
		has_done_a_climate_risk_assessment: 'No Response Yet',
	};

	const infoKeys = Object.keys(initialInfoState);
	const [supplierInfo, setSupplierInfo] = useState(initialInfoState);
	const [companyName, setCompanyName] = useState('');

	useEffect(() => {
		const getSupplierInfo = async () => {
			const incomingData = await getSupplierInfoFromDB(params.supplierid);
			setCompanyName(incomingData['company_name']);
		};
		getSupplierInfo();
	}, [params.supplierid]);

	useEffect(() => {
		const getData = async () => {
			const assessment_one_id = await getAssessmentIdFromDB(
				params.supplierid,
				10001
			);
      const assessment_one_response = await getAssessmentResponse('supplier_initial_assessment_1', assessment_one_id);
      
      console.log('assessment_one_response: ', assessment_one_response);
      const hasClimatePolicy = assessment_one_response?.has_climate_policy || 'No Response Yet';
      const hasClimateTeam = assessment_one_response?.has_climate_team || 'No Response Yet';

      setSupplierInfo((prevState) => ({
        ...prevState,
        has_climate_policy: hasClimatePolicy,
        has_climate_team: hasClimateTeam,
      }))
      
    };
		getData();
	}, [params.supplierid]);
	
  useEffect(() => {
		const getData = async () => {
			const assessment_two_id = await getAssessmentIdFromDB(
				params.supplierid,
				10002
			);
      const assessment_two_response = await getAssessmentResponse('supplier_initial_assessment_2', assessment_two_id);
      
      console.log('assessment_two_response: ', assessment_two_response);
      const ghgSet = assessment_two_response?.ghg_reduction_targets_set || 'No Response Yet';
      const offsetInPlace = assessment_two_response?.offset_program_in_place || 'No Response Yet';
      const riskAssessment = assessment_two_response?.has_done_a_climate_risk_assessment || 'No Response Yet'

      setSupplierInfo((prevState) => ({
        ...prevState,
        ghg_reduction_targets_set: ghgSet,
        offset_program_in_place: offsetInPlace,
        has_done_a_climate_risk_assessment: riskAssessment,
      }))
      
    };
		getData();
	}, [params.supplierid]);

	const supplierInfoFields = infoKeys.map((key, index) => {
		return (
			<SupplierInfoField
				key={index.toString()}
				fieldName={key}
				fieldValue={supplierInfo[key]}
			/>
		);
	});

	return (
		<>
			<SidebarPanel />

			<main className={styles.supplierInfoPageMain}>
				<nav>
					<TopBar />
				</nav>
				<Box m='20px'>
					<Header title='SUPPLIER INFORMATION' subtitle={companyName} />
				</Box>
				<div className={styles.supplierInfoContainer}>
					<div className={styles.supplierInfoPageWrapper}>
						{supplierInfoFields}
					</div>
				</div>
			</main>
		</>
	);
};

export default SupplierInfoPage;

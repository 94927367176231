import { supabase } from '../../../Utilities/SupabaseClient';

// Returns an array for the bar chart. The array contains
// objects of emissions for the suppliers.
const getAssessmentIdFromDB = async (supplierid, assessmentNumber) => {
	try {
		const { data, err } = await supabase
			.from('assessments_sent')
			.select('*')
      .eq('assessment_number', assessmentNumber)
			.eq('supplier_uuid', supplierid)
		// Error block here in case the catch block does not
		// recognize the error. Not sure how Supabase handles the error
		// message so including this here. May not be necessary.
		if (err) throw err;
		if (data) {
      const assessment_uuid = data[0]['assessment_uuid'];
      
      return assessment_uuid;
      
		}
	} catch (e) {
		console.log(e);
		throw e;
	}
};

export default getAssessmentIdFromDB;
